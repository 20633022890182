class Scroller {
    constructor() {
        this.$links = document.querySelectorAll('a.internal')

        if(this.$links.length > 0) {
            this.links = [];
            this.headerHeight = document.getElementById('site-header').getBoundingClientRect().height

            this.init()
            this.registerListeners()
        }
    }

    init() {
        this.$links.forEach($link => {
            let $target = document.getElementById($link.hash.split('#')[1]);

            let link = {
                $el: $link,
                $target: $target
            }

            this.links.push(link)

            $link.addEventListener('click', (e) => {
                e.preventDefault()

                window.scrollTo({
                    top: link.$target.offsetTop - this.headerHeight,
                    behavior: 'smooth'
                });

                this.$links.forEach($l => {
                    $l.classList.remove('active')
                })
                $link.classList.add('active')
            })
        })
    }

    registerListeners() {
        let activeLink = this.links[0]
        document.addEventListener('scroll', (e) => {
            this.links.find((link) => {
                let offsetTop = link.$target.offsetTop
                let offsetBottom = offsetTop + link.$target.getBoundingClientRect().height

                if(offsetTop > (window.scrollY) && offsetTop < (window.scrollY + window.innerHeight - 200 )) {
                    activeLink = link
                    return true
                }

                return false
            })


            this.$links.forEach($l => {
                $l.classList.remove('active')
            })
            activeLink.$el.classList.add('active')
        }, { passive: true })
    }
}