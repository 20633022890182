class Hotspots {
    constructor(props) {
        this.$containers = document.querySelectorAll('.team-markers');
        this.hotspots = []

        if(this.$containers.length > 0) {
            this.init()
            this.calculate()

            window.addEventListener('resize', this.calculate.bind(this))
        }
    }

    init() {
        this.$containers.forEach($container => {
            let hotspot = {
                $container: $container,
                image: $container.querySelector('img'),
                $markers: $container.querySelectorAll('.marker'),
                $details: $container.querySelectorAll('.details')
            }

            $container.querySelectorAll('.bullet').forEach($bullet => {
                $bullet.addEventListener('mouseenter', () => {
                    hotspot.$markers.forEach($marker => { $marker.classList.remove('active') })
                    $bullet.parentNode.classList.add('active')
                })

                $bullet.addEventListener('touchstart', () => {
                    hotspot.$markers.forEach($marker => { $marker.classList.remove('active') })
                    $bullet.parentNode.classList.add('active')
                })
            })

            this.hotspots.push(hotspot)
        })
    }

    calculate() {
        this.hotspots.forEach(hotspot => {
            let imgHeight = hotspot.image.offsetHeight
            hotspot.$details.forEach($details => {
                let top = $details.parentNode.style.top
                let margin = imgHeight - ((imgHeight/100) * parseFloat(top)) + ((window.innerWidth > 640)? 120: 50)
                $details.style.top = margin + 'px'
                $details.querySelector('.line').style.height = margin - 40 + 'px'
            })
        })
    }
}