class Modal {
    constructor() {
        this.$body = document.querySelector('body');
        this.$modals = document.querySelectorAll('.modal')
        this.modals = []

        if (this.$modals.length > 0) {
            this.init()
        }
    }

    init() {
        this.$modals.forEach(($modal) => {
            let modal = {
                $el: $modal,
                $trigger: $modal.querySelector('.modal-trigger')
            }

            modal.$trigger.addEventListener('click', (e) => {
                modal.$el.classList.toggle('active')
                this.$body.classList.toggle('nav-active')
            })

            this.modals.push(modal)
        })
    }
}