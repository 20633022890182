class Navigation
{
    constructor() {
        this.$body = document.querySelector('body');
        this.$container = document.getElementById('site-navbar');
        this.$navOpener = document.getElementById('open-navigation')
        this.$navCloser = document.querySelectorAll('.close-navigation')
        this.$mainNavigation = document.getElementById('main-navigation')
        this.$subNavigations = this.$mainNavigation.querySelectorAll('.sub-navigation')
        this.$secondaryNav = document.getElementById('secondMenu');

        this.init()
        this.registerListeners()
    }

    init() {
        // get main nav height
        let mainNavHeight = this.$mainNavigation.scrollHeight

        // get highest sub navigation
        this.$subNavigations.forEach($el => {
            let curHeight = $el.scrollHeight

            if(curHeight > mainNavHeight)
                mainNavHeight = curHeight
        })

        // only apply menu height above breakpoint medium
        if (window.innerWidth > 640) {
            this.$mainNavigation.style.height = mainNavHeight + 'px'
        } else {
            this.$mainNavigation.style.height = ''
        }

        this.$subNavigations.forEach(($subNav) => {
            if (window.innerWidth > 640) {
                $subNav.style.top = ''
            } else {
                $subNav.style.top = this.$mainNavigation.offsetTop + 'px'
            }
        })
    }

    registerListeners() {
        this.$navOpener.addEventListener('click', this.open.bind(this))

        this.$navCloser.forEach($closer => {
            $closer.addEventListener('click', this.close.bind(this))
        })

        this.$mainNavigation.querySelectorAll('li.has-sub-navigation > a').forEach($el => {
            $el.addEventListener('click', this.openSubnavigation.bind(this))
        })

        this.$mainNavigation.querySelectorAll('.sub-navigation a.navigation-back').forEach($el => {
            $el.addEventListener('click', this.closeSubnavigation.bind(this))
        })

        // calculate new menu hight on resize
        window.addEventListener('resize', e => {
            this.init()
        })
    }

    open() {
        document.addEventListener('click', this.clickOutside.bind(this), true);
        this.$container.classList.remove('closed')
        this.$body.classList.add('nav-active')
    }

    close() {
        document.removeEventListener('click', this.clickOutside.bind(this), true);
        this.$container.classList.add('closed')
        this.$body.classList.remove('nav-active')
    }

    openSubnavigation(e) {
        e.preventDefault();
        e.target.nextElementSibling.classList.add('is-active')
        this.$secondaryNav.classList.add('hidden')
    }

    closeSubnavigation(e) {
        e.preventDefault();
        e.target.parentNode.parentNode.classList.remove('is-active')
        this.$secondaryNav.classList.remove('hidden')
    }

    clickOutside(e) {
        if(!this.$container.contains(e.target)) {
            this.close()
        }
    }
}