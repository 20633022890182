class Sticky {
    constructor() {
        this.offset = 20

        this.prevScrollPos = 0
        this.$sticky = document.querySelectorAll('[data-sticky]')
        this.elements = []
        this.windowWidth = 0

        if(this.$sticky.length > 0) {
            this.$header = document.getElementById('site-header')
            this.init()
            this.calculateData()
            this.registerEventListeners()
        }
    }

    init() {
        this.$sticky.forEach(($el) => {
            let $container = this.findContainer($el.parentNode)

            this.elements.push({
                $el: $el,
                $container: $container,
                elHeight: $el.offsetHeight,
                start: $container.offsetTop,
                breakpoint: $el.getAttribute('data-sticky-bp') ?? 0,
                offset: {
                    small: window.innerHeight - 60,
                    large: this.$header.scrollHeight + 20
                }
            })
        })
    }
    

    registerEventListeners() {
        window.addEventListener('resize', (e) => {
            this.calculateData()
        })

        document.addEventListener('scroll', (e) => {
            let curScrollPos = window.scrollY

            // let scrollDirection = curScrollPos > this.prevScrollPos? 'down': 'up';

            let breakpoint = this.windowWidth >= 1024? 'large': 'small'

            this.elements.forEach((el) => {
                let startOffset = window.scrollY + el.offset[breakpoint]


                if (el.breakpoint <= this.windowWidth && el.start <= startOffset) {
                    if(startOffset + el.elHeight < el.$container.offsetHeight + el.start) {
                        el.$el.style['margin-top'] = el.offset[breakpoint] + 'px'
                        el.$el.style.top = 0
                        el.$el.classList.remove('anchored')
                        el.$el.classList.add('stuck')
                    } else {
                        el.$el.style.top = el.$container.offsetHeight - el.elHeight + 'px'
                        el.$el.style['margin-top'] = ''
                        el.$el.classList.add('anchored')
                    }
                } else {
                    el.$el.style['margin-top'] = ''
                    el.$el.style.top = ''
                    el.$el.classList.remove('stuck')
                }
            })

            this.prevScrollPos = curScrollPos
        }, { passive: true })
    }

    calculateData() {
        this.windowWidth = window.innerWidth

        // update container offset top
        this.elements.forEach((el) => {
            el.start = el.$container.offsetTop
        })
    }

    findContainer($el) {
        if(!$el.hasAttribute('data-sticky-container')) {
            return this.findContainer($el.parentNode)
        } else {
            return $el
        }
    }
}