class Search {
    constructor() {
        this.$fields = document.querySelectorAll('.search-field')

        if(this.$fields.length > 0) {
            this.fields = []

            this.init()
        }
    }

    init() {
        this.$fields.forEach(($field) => {
            let field = {
                $el: $field,
                $input: $field.querySelector('input'),
                $button: $field.querySelector('button.clear')
            }

            field.$input.addEventListener('focus', () => {
                this.onFocusIn(field)
            })

            field.$input.addEventListener('focusout', (e) => {
                this.onFocusOut(field)
            })

            field.$input.addEventListener('keyup', (e) => {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    field.$input.getSizedParentNode().submit()
                }
            })

            field.$button.addEventListener('click', (e) => {
                this.onFocusOut(field)
            })

            this.fields.push(field)
        })
    }

    onFocusIn(field) {
        field.$el.classList.add('active')
        field.$input.placeholder = field.$el.dataset.alternateText
    }

    onFocusOut(field) {
        field.$el.classList.remove('active')
        // field.$input.value = ''
        field.$input.placeholder = field.$el.dataset.defaultText
    }
}