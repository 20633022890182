class HeroBoxHandler {
    constructor() {
        this.$boxes = document.querySelectorAll('.hero-box');

        this.registerListeners()
    }

    registerListeners() {
        this.$boxes.forEach($box => {
            let externalLink = $box.querySelector('a')

            $box.addEventListener('touchstart', (e) => {
                if ((externalLink !== undefined) && !externalLink.contains(e.target)) {
                    $box.classList.toggle('active')

                    this.$boxes.forEach($b => {
                        if ($b !== $box) {
                            $b.classList.remove('active')
                        }
                    })
                }
            }, {passive: true})

            $box.addEventListener('mouseenter', (e) => {
                this.$boxes.forEach($b => {
                    $b.classList.remove('active')
                })

                $box.classList.add('active')
            }, {passive: true})

            $box.addEventListener('mouseleave', (e) => {
                $box.classList.remove('active')
            }, {passive: true})
        })
    }
}