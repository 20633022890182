class ApplicationForm {
    constructor() {
        var fileField = new DnDFileFields('.form_field_file');

        let form = document.querySelector('form.application-form')

        if (form !== undefined && form) {
            form.addEventListener('submit', function (e) {
                e.preventDefault();
                var valid = true;

                e.target.querySelectorAll('.custom-select').forEach(function (fieldContainer, i) {
                    if (!fieldContainer){
                        return;
                    }

                    // var select = fieldContainer.querySelector('select[data-select-required]');

                    // select.addEventListener('change', function (e) {
                    //     if (select.value) {
                    //         fieldContainer.classList.remove('error');
                    //     }
                    // }, {once: true});

                    // if (select) {

                    //     if (!select.value) {
                    //         valid = false;
                    //         fieldContainer.classList.add('error');
                    //     } else {
                    //         fieldContainer.classList.remove('error');
                    //     }
                    // }
                });

                e.target.querySelectorAll('.form_field_file').forEach(function (fieldContainer, i) {
                    var fileField = fieldContainer.querySelector('input[type="file"][data-file-required]');

                    if (fileField) {
                        if ((fileField.files.length <= 0) || fieldContainer.classList.contains('error')) {
                            valid = false;
                            fieldContainer.classList.add('error');
                        } else {
                            fieldContainer.classList.remove('error');
                        }
                    }
                })

                if (valid)
                    e.target.submit();
            });
        }
    }
}