class DnDFileFields {
  constructor (selector) {
    this.fields = []

    document.querySelectorAll(selector).forEach(fieldContainer => {
      this.fields.push(new DnDFileField(fieldContainer))
    })
  }
}

class DnDFileField {
  constructor ($container) {
    this.ddCounter = 0

    this.$container = $container
    this.$dropZone = $container.querySelector('.file_dd_dropzone');
    this.$field = $container.querySelector('input[type="file"]')
    this.multiple = this.$field.hasAttribute('multiple')

    if (this.multiple) {
      this.$preview = $container.nextElementSibling
    } else {
      this.$preview = $container.querySelector('.file_preview')
    }

    this.addListeners()
  }

  addListeners () {
    this.$field.addEventListener('change', e => {

      if (this.multiple) {
        var fileNames = '<ul>'

        for (var i = 0; i < this.$field.files.length; i++) {
          fileNames += '<li><span>' + this.$field.files[i].name + '</span></li>'
        }

        this.$preview.innerHTML = fileNames + '</ul>'
      } else {
        this.$preview.innerHTML = this.$field.files[0].name;
      }

      this.$container.classList.remove('error')
      this.$container.classList.add('successful')
    })

    this.$container.addEventListener('dragenter', e => {
      e.preventDefault()
      this.ddCounter++
      this.$container.classList.add('dd-file-over')
    });

    this.$container.addEventListener('dragover', e => {
      e.preventDefault()
    });

    ['dragleave', 'dragend'].forEach(type => {
      this.$container.addEventListener(type, e => {
        this.ddCounter--;

        if(this.ddCounter === 0)
          this.$container.classList.remove('dd-file-over');
      })
    });


    this.$container.addEventListener('drop', e => {
      e.preventDefault();
      this.ddCounter = 0;

      if (e.dataTransfer.files.length) {
        if (this.multiple) {
          var successful = true
          var fileNames = '<ul>'

          for (var i = 0; i < e.dataTransfer.files.length; i++) {
            if (e.dataTransfer.files[i].type !== 'application/pdf') {
              successful = false
              break
            }

            fileNames += '<li><span>' + e.dataTransfer.files[i].name + '</span></li>'
          }

          if (successful) {
            this.$field.files = e.dataTransfer.files
            this.$preview.innerHTML = fileNames + '</ul>'
            this.$container.classList.remove('error')
            this.$container.classList.add('successful')
          } else {
            this.$field.files = null
            this.$preview.innerHTML = ''
            this.$container.classList.remove('successful')
            this.$container.classList.add('error')
            this.$container.classList.remove('dd-file-over')
          }

        } else {
          if (e.dataTransfer.files[0].type === 'application/pdf') {
            this.$field.files = e.dataTransfer.files
            this.$preview.innerHTML = e.dataTransfer.files[0].name
            this.$container.classList.remove('error')
            this.$container.classList.add('successful')
          } else {
            this.$field.files = null
            this.$preview.innerHTML = ''
            this.$container.classList.remove('successful')
            this.$container.classList.add('error')
          }
        }

        this.$container.classList.remove('dd-file-over')
      }
    })
  }
}
