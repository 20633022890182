console.info(
    "%ccoded by DOCONO%c 🤘🏻 https://docono.io\n",
    "padding: 5px; background: #ea4e66; color: #ffffff; font-size: 11px;",
    ""
);

window.onload = function () {
    new Navigation();
    new Header();
    new Search();
    new Subsite();
    new HeroBoxHandler();
    new Scroller();
    new Sticky();
    new Accordion();
    new Modal();
    new Hotspots();
    new FormValidation();

    if (document.getElementById("application-form") !== undefined) {
        new ApplicationForm();
    }


    if (document.querySelectorAll(".image-slider").length > 0) {
        let $sliders = document.querySelectorAll(".image-slider");
        $sliders.forEach(slider => {
            tns({
                container: slider,
                items: 1,
                speed: 400,
                autoplay: false,
                autoHeight: false,
                controls: false,
                controlsText: ["", ""],
                nav: false,
                autoplayButtonOutput: false,
                mouseDrag: true,
                responsive: {
                    640: {
                        controls: true,
                    },
                },
            });
        });
    }
};
