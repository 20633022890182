class Header
{
    constructor() {
        this.$header = document.getElementById('site-header')
        this.$scrollTop = document.getElementById('scroll-up')
        this.foldInMinScroll = 300;

        this.scrollTopPos = 0
        this.scrollDirection = 'down'

        this.init()
        this.registerListeners()
    }

    init() {
        this.$header.nextElementSibling.style['margin-top'] = this.$header.scrollHeight + 'px'
    }

    registerListeners() {
        document.addEventListener('scroll', this.onScroll.bind(this), { passive: true })
        window.addEventListener('resize', this.init.bind(this))
        this.$header.querySelector('.site__header__separator').addEventListener('mouseenter', this.foldOut.bind(this))

        this.$scrollTop.addEventListener('click', (e) => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        })
    }


    onScroll()
    {
        let curScrollPos = window.scrollY

        let scrollDirection = (curScrollPos > this.scrollTopPos)? 'down': 'up';
        this.scrollTopPos = curScrollPos;

        if(scrollDirection === 'down') {
            if(curScrollPos >= this.foldInMinScroll) {
                this.foldIn()

                this.$scrollTop.style.visibility = 'visible'
                this.$scrollTop.style.opacity = 1
            }
        } else {
            this.foldOut()

            if(curScrollPos <= this.foldInMinScroll) {
                this.$scrollTop.style.visibility = ''
                this.$scrollTop.style.opacity = ''
            }
        }
    }

    foldIn() {
        this.$header.classList.add('closed')
    }

    foldOut() {
        this.$header.classList.remove('closed')

    }

}