class FormValidation {
    constructor() {
        const forms = document.querySelectorAll('form')
        this.$forms = [...forms].filter(f => f.getAttribute('novalidate'))

        this.init()
    }

    init() {
        for (let $form of this.$forms) {
            $form.addEventListener('submit', (e) => {
                // check if form is valid
                if($form.checkValidity()) {
                    return
                }

                // prevent submission
                e.preventDefault()

                $form.querySelectorAll('input, textarea').forEach($field => {
                    if($field.nextElementSibling && $field.nextElementSibling.classList.contains('error')) {
                        $field.nextElementSibling.remove()
                    }

                    // skip valid fields
                    if ($field.checkValidity()) {
                        $field.classList.remove('error')
                        return
                    }

                    if(!$field.nextElementSibling || !$field.nextElementSibling.classList.contains('error')) {
                        $field.classList.add('error')

                        const $error = document.createElement('div')
                        $error.classList.add('error')
                        $error.innerHTML = $field.validationMessage

                        $field.after($error)

                        const fieldValidationCheck = (e) => {
                            if($field.checkValidity()) {
                                $field.classList.remove('error')

                                if($field.nextElementSibling && $field.nextElementSibling.classList.contains('error')) {
                                    $field.nextElementSibling.remove()
                                }

                                $field.removeEventListener('change', fieldValidationCheck)
                            } else {
                                if($field.nextElementSibling && $field.nextElementSibling.classList.contains('error')) {
                                    $field.nextElementSibling.innerHTML = $field.validationMessage
                                }
                            }
                        }

                        $field.addEventListener('change', fieldValidationCheck)
                    }
                })

                const $firstError = document.querySelector('.error')

                if ($firstError) {
                    window.scrollTo({top: $firstError.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 260, behavior: 'smooth'});
                }
            })
        }
    }
}