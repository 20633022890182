class Subsite {
    constructor() {
        this.$sections = document.querySelectorAll('.project-section')
        this.sections = []

        if(this.$sections.length > 0) {
            this.init()
        }
    }

    init() {
        this.$sections.forEach(($section) => {
            let section = {
                $el: $section,
                $trigger: $section.querySelector('.project-section__header'),
                $content: $section.querySelector('.project-section__content')
            }

            this.sections.push(section)

            if(section.$trigger !== undefined && section.$trigger) {
                section.$trigger.addEventListener('click', (e) => {

                    if (!section.$el.classList.contains('active')) {
                        section.$el.classList.add('active')
                        section.$content.style['max-height'] = '100%' //section.$content.scrollHeight + 'px'
                    } else {
                        section.$el.classList.remove('active')
                        section.$content.style['max-height'] = ''
                    }
                })
            }
        })
    }

    closeTabs () {
        this.sections.forEach(section => {
            section.$el.classList.remove('active')
            section.$content.style['max-height'] = ''
        })
    }

}