class Accordion {
    constructor() {
        this.$tabs = document.querySelectorAll('.accordion__tab')
        this.tabs = []

        if(this.$tabs.length > 0) {
            this.init()
        }
    }

    init() {
        this.$tabs.forEach($tab => {
            let tab = {
                $el: $tab,
                $separator: $tab.querySelector('.tab__separator'),
                $title: $tab.querySelector('h4')
            }
            this.registerTabListeners(tab)
            this.tabs.push(tab)
        })
    }

    registerTabListeners(tab) {
        tab.$el.addEventListener('mouseover', e => {
            if(!tab.$el.classList.contains('active'))
                tab.$separator.style['max-width'] = tab.$title.getBoundingClientRect().width + 'px'
        })

        tab.$el.addEventListener('mouseleave', e => {
            tab.$separator.style['max-width'] = ''
        })

        tab.$el.addEventListener('click', e => {
            let isOpen = tab.$el.classList.contains('active')

            // close all tabs
            this.$tabs.forEach($tab => {
                $tab.classList.remove('active')
            })

            // open tab otherwise close tab and pretend to be hover
            if (!isOpen) {
                tab.$separator.style['max-width'] = ''
                tab.$el.classList.add('active')
            } else if (window.innerWidth > 640) {
                tab.$separator.style['max-width'] = tab.$title.getBoundingClientRect().width + 'px'
            }
        })
    }
}